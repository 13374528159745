import React from 'react'
import MainLayout from '@root/src/components/layout/Main'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { Page404 as PageNotFound } from '@islandsstofa/react'

const Page404 = () => {
  const { language } = useLanguage()

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout>
        <PageNotFound />
      </MainLayout>
    </GlobalDataProvider>
  )
}

export default Page404
